import {useCallback} from 'react';
import {useToast} from '@halp/ui';
import type {GraphQLDocumentQueryKey, GraphQLError} from '@halp/api/graphql';
import {GraphQLClientError, useMutation} from '@halp/api/graphql';
import {Form, type Props as FormProps} from './Form';
import {ResponseError} from './ResponseError';
import type {TypedDocumentNode} from '@graphql-typed-document-node/core';
import type {FieldValues, UseFormSetError} from 'react-hook-form';

export interface Props<Vars extends FieldValues, Data>
	extends Omit<FormProps<Vars, Data>, 'onError'> {
	mutationDocument: TypedDocumentNode<Data, Vars>;
	onError?: (errors: GraphQLError[]) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	invalidateQueries?: GraphQLDocumentQueryKey<any, any>[];
	withToast?: boolean;
	className?: string;
}

export function GraphQLForm<Vars extends FieldValues, Data>({
	mutationDocument,
	invalidateQueries,
	withToast = true,
	onError,
	...props
}: Props<Vars, Data>) {
	const addToast = useToast();
	const [_mutate, {mutateAsync}] = useMutation(mutationDocument, {
		invalidateQueries,
	});

	const mutateSubmit = useCallback(
		(vars: Vars, setError: UseFormSetError<Vars>) => {
			return mutateAsync(vars)
				.then((result) => {
					if (withToast) addToast({type: 'success'});
					return result as Data;
				})
				.catch((reason) => {
					if (withToast) addToast({type: 'error'});

					if (reason instanceof GraphQLClientError) {
						reason.response.errors?.forEach((error, index) => {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							setError(`server.${index}` as any, {
								type: 'manual',
								message: error.message,
							});
						});

						if (reason.response.errors) {
							onError?.(reason.response.errors);
						}

						return Promise.reject(
							new ResponseError({
								message: reason.message,
								statusCode: reason.response.status,
							}),
						);
					}

					setError('root.server', {
						type: 'manual',
						message: 'Something has gone wrong',
					});

					return Promise.reject(reason);
				});
		},
		[withToast, addToast, mutateAsync, onError],
	) as (vars: Vars) => Promise<Data>;

	return <Form {...props} handleSubmit={mutateSubmit} />;
}
