import {useEffect, useState} from 'react';
import {getCountryNames} from './countries';
import type {CountryName} from 'country-region-data';

export function useCountryNames() {
	const [countryNames, setCountryNames] = useState<CountryName[]>([]);

	useEffect(() => {
		getCountryNames().then(setCountryNames);
	}, [setCountryNames]);

	return countryNames;
}
