export {type Options} from 'react-select';
import classNames from 'classnames';
import {lazy, Suspense} from 'react';
import style from './Select.module.css';
import type {ComponentProps} from 'react';
import type {StateManagerProps} from 'react-select/dist/declarations/src/stateManager';
import type {ControlProps, GroupBase, ValueContainerProps} from 'react-select';
import type StateManagedSelect from 'react-select/dist/declarations/src/stateManager';

const ReactSelect = lazy(() => import('react-select')) as StateManagedSelect;

const ReactAsyncSelect = lazy(() => import('react-select/async'));

interface Props {
	wrap?: 'nowrap';
}

export function AsyncSelect(props: ComponentProps<typeof ReactAsyncSelect>) {
	return (
		<Suspense fallback={<input />}>
			<ReactAsyncSelect {...props} />
		</Suspense>
	);
}

export function Select<
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>({
	wrap,
	classNames: cn,
	...props
}: StateManagerProps<Option, IsMulti, Group> & Props) {
	const selectClassName = classNames(style.Select, props.className);
	const control = (state: ControlProps<Option, IsMulti, Group>) =>
		classNames(wrap === 'nowrap' && style.NoWrap, cn?.control?.(state));
	const valueContainer = (state: ValueContainerProps<Option, IsMulti, Group>) =>
		classNames(wrap === 'nowrap' && style.NoWrap, cn?.valueContainer?.(state));

	return (
		<Suspense fallback={<input />}>
			<ReactSelect
				{...props}
				classNames={{
					...cn,
					control: control,
					valueContainer: valueContainer,
				}}
				className={selectClassName}
				classNamePrefix="form-select"
			/>
		</Suspense>
	);
}
