import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import type {FunnelStage, QualificationStatus} from '@halp/util/constants';
import {friendlyURI, getURLQueryParameters, parseQueryString} from '@halp/util';
import {useQuery} from '@halp/api/graphql';
import {AdminCoachesIndexDocument} from '../../Coaches/AdminCoachesIndex.query';
import {useUserType} from '../../Users';
import {Paths} from '../../Paths';
import {QueryTable} from '../../DataTable';
import {StudentFilters} from '../StudentFilters';
import {StudentIndexDocument} from './StudentIndex.query';
import {
	useStudentsTableColumns,
	type StudentsTableColumnsType,
} from './StudentsTableColumns';
import type {DateRange} from '../StudentFilters/StudentsFilters';
import type {StudentFiltersType} from '../StudentFilters';
import type {RowData} from '../../DataTable';
import type {MemberStatus} from './types';

interface Props {
	prefix: string;
	filters?: StudentFiltersType[];
	columns: StudentsTableColumnsType[];
	filterStudents?: 'all' | 'verified' | 'profile_complete';
	coachId?: string;
	studentStatus?: FunnelStage | 'ARCHIVED' | 'ALL';
	sortColumn?: StudentsTableColumnsType;
	sortType?: 'asc' | 'desc';
}

export function StudentsTable({
	prefix,
	filters,
	columns,
	filterStudents,
	coachId,
	studentStatus,
	sortColumn = 'insertedAt',
	sortType = 'desc',
}: Props) {
	const router = useRouter();
	const userType = useUserType();
	const tableColumns = useStudentsTableColumns(columns);

	const {
		qualStatus,
		intakePeriod,
		coach,
		memberStatus,
		signUpFrom,
		signUpTo,
		country,
		onshore,
	} = router.query;

	const {data: coachData} = useQuery(AdminCoachesIndexDocument);
	const coaches = coachData?.coaches;

	const [qualStatusFilter, setQualStatusFilter] = useState<
		QualificationStatus | undefined
	>(parseQueryString(qualStatus) as QualificationStatus);

	const [intakePeriodFilter, setIntakePeriodFilter] = useState<string[]>([
		...(intakePeriod && !Array.isArray(intakePeriod) ? [intakePeriod] : []),
	]);

	const signupFrom =
		signUpFrom && !Array.isArray(signUpFrom) ? signUpFrom : null;
	const signupTo = signUpTo && !Array.isArray(signUpTo) ? signUpTo : null;

	const [signUpDateFilter, setSignUpDateFilter] = useState<
		DateRange | undefined
	>(
		signupFrom && signupTo
			? {from: new Date(signupFrom), to: new Date(signupTo)}
			: undefined,
	);

	const [memberStatusFilter, setMemberStatusFilter] = useState<
		MemberStatus | undefined
	>(parseQueryString(memberStatus) as MemberStatus);

	const [countryFilter, setCountryFilter] = useState<string | undefined>(
		parseQueryString(country),
	);

	const [onshoreFilter, setOnshoreFilter] = useState<boolean | undefined>(
		onshore ? parseQueryString(onshore) === 'true' : undefined,
	);

	const selectedCoachId = parseQueryString(coach);
	const selectedCoach = coaches?.find((c) => c.id === selectedCoachId);

	const [coachFilter, setCoachFilter] = useState<
		| {
				id: string;
				name: string;
		  }
		| undefined
	>(
		selectedCoach
			? {id: selectedCoach.id, name: selectedCoach.user.name ?? ''}
			: undefined,
	);

	useEffect(() => {
		setCoachFilter(
			selectedCoach
				? {id: selectedCoach.id, name: selectedCoach.user.name ?? ''}
				: undefined,
		);
	}, [selectedCoach, coaches]);

	const funnelStage =
		studentStatus === 'ARCHIVED' || studentStatus === 'ALL'
			? null
			: studentStatus;

	const archivedFilter =
		studentStatus === 'ARCHIVED'
			? true
			: filterStudents === 'all'
				? null
				: false;

	return (
		<QueryTable
			key={`${sortColumn}-${sortType}`}
			infiniteQuery={StudentIndexDocument}
			sortColumn={sortColumn}
			sortType={sortType}
			dataKey="students.edges"
			enableSearch
			pushSearchQueryParams
			options={{
				limit: 30,
				variables: {
					filter: filterStudents,
					funnelStage: funnelStage ?? null,
					qualificationStatus: qualStatusFilter,
					intakePeriods: intakePeriodFilter,
					signupDate: signUpDateFilter
						? [signUpDateFilter.from, signUpDateFilter.to]
						: null,
					coachId: coachFilter?.id || coachId,
					memberStatus: memberStatusFilter,
					country: countryFilter,
					onshore: onshoreFilter,
					archived: archivedFilter,
				},
			}}
			onRowClick={(row: RowData) => {
				router.push({
					pathname: Paths.users.overview.index.url(
						prefix,
						row.user.id,
						friendlyURI(row.user.name),
						userType,
					),
					query: getURLQueryParameters(),
				});
			}}
			columns={tableColumns}
			rowHeight={65}
		>
			<StudentFilters
				filters={filters}
				coaches={coaches}
				qualificationStatus={qualStatusFilter}
				setQualStatus={setQualStatusFilter}
				intakePeriod={intakePeriodFilter}
				setIntakePeriod={setIntakePeriodFilter}
				signUpDate={signUpDateFilter}
				setSignUpDate={setSignUpDateFilter}
				coach={coachFilter}
				setCoach={setCoachFilter}
				memberStatus={memberStatusFilter}
				setMemberStatus={setMemberStatusFilter}
				country={countryFilter}
				setCountry={setCountryFilter}
				onshore={onshoreFilter}
				setOnshore={setOnshoreFilter}
			/>
		</QueryTable>
	);
}
