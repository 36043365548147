import {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import {useImperativeQuery} from '@halp/api/graphql';
import {Stack, Paragraph} from '@halp/ui';
import type {IntegerRange} from '@halp/ui';
import type {LevelOfStudy} from '@halp/util';
import {Paths} from '../../Paths';
import {useUserType} from '../../Users';
import {ProgramFilters} from '../../Programs/ProgramFilters/ProgramFilters';
import {ProgramIndexDocument} from '../../Programs/ProgramIndex.query';
import {Item} from './Item';
import style from './CommandPalette.module.css';
import type {ProgramLengthRange} from '../../Programs/types';

interface Program {
	id: string;
	name: string;
	school: string;
}

interface Props {
	search: string;
	onClose: VoidFunction;
}

export function SearchPrograms({search, onClose}: Props) {
	const router = useRouter();
	const userType = useUserType();

	const [levelOfStudyFilter, setLevelOfStudyFilter] = useState<
		LevelOfStudy | undefined
	>();
	const [schoolFilter, setSchoolFilter] = useState<string | undefined>();
	const [subjectFilter, setSubjectFilter] = useState<string | undefined>();
	const [majorFilter, setMajorFilter] = useState<string | undefined>();
	const [countryFilter, setCountryFilter] = useState<string | undefined>();
	const [programs, setPrograms] = useState<Program[] | null>(null);
	const [tuitionRange, setTuitionRange] = useState<IntegerRange | undefined>();
	const [programLengthRange, setProgramLengthRange] = useState<
		ProgramLengthRange | undefined
	>();
	const [province, setProvince] = useState<string | undefined | null>();
	const programSearchQuery = useImperativeQuery(ProgramIndexDocument);

	useEffect(() => {
		async function searchStudents(search: string) {
			const data = await programSearchQuery({
				variables: {
					search: search,
					tuition: tuitionRange,
					programLength: programLengthRange,
					levelOfStudy: levelOfStudyFilter,
					school: schoolFilter,
					subject: subjectFilter,
					major: majorFilter,
					country: countryFilter,
					limit: 25,
				},
			});

			const programs = data?.programsCollection.programs
				? data?.programsCollection.programs.map((program) => ({
						id: program.id,
						name: program.name,
						school: program.school.name,
					}))
				: null;

			setPrograms(programs);
		}

		searchStudents(search);
	}, [
		search,
		programSearchQuery,
		levelOfStudyFilter,
		schoolFilter,
		subjectFilter,
		majorFilter,
		countryFilter,
		tuitionRange,
		programLengthRange,
	]);

	return (
		<>
			<div className={style.FilterList}>
				<ProgramFilters
					shouldPushQueryParams={false}
					schoolId={schoolFilter}
					setSchool={setSchoolFilter}
					subjectId={subjectFilter}
					setSubject={setSubjectFilter}
					majorId={majorFilter}
					setMajor={setMajorFilter}
					country={countryFilter}
					province={province}
					setProvince={setProvince}
					setCountry={setCountryFilter}
					setLevelOfStudy={setLevelOfStudyFilter}
					tuitionRange={tuitionRange}
					setTuitionRange={setTuitionRange}
					programLengthRange={programLengthRange}
					setProgramLengthRange={setProgramLengthRange}
				/>
			</div>
			{programs?.map((program) => (
				<Item
					key={program.id}
					value={`${program.name} ${program.school}`}
					onSelect={() => {
						router.push(Paths.programs.programs.show.url(program.id, userType));
						onClose();
					}}
				>
					<Stack fullWidth justifyContent="space-between">
						<Paragraph>{program.name}</Paragraph>
						<Paragraph>{program.school}</Paragraph>
					</Stack>
				</Item>
			))}
		</>
	);
}
