import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './SkeletonText.module.css';

export interface Props {
	count?: number;
	width?: 'small' | 'medium' | 'large';
}

export function SkeletonText({count = 1, width}: Props) {
	const classes = classNames(
		style.SkeletonText,
		CSSVariants(style, 'Width', width),
	);

	const lines = [...Array(count)].map((_val, idx) => {
		return (
			<span key={idx} className={classes}>
				&zwnj;
			</span>
		);
	});

	return <>{lines}</>;
}
