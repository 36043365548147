import {Fragment, useMemo, useState} from 'react';
import {Table, Column, HeaderCell, Cell} from 'rsuite-table';
import classNames from 'classnames';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {Button, Pagination, Paragraph, useAsyncCss} from '@halp/ui';
import {accessObject} from '@halp/util';
import style from './DataTable.module.css';
import type {TableProps, ColumnProps, RowDataType} from 'rsuite-table';
import type {ReactNode} from 'react';

interface DataColumn<Data extends RowData> extends ColumnProps<Data> {
	label: string;
	key: string;
	filterable?: boolean;
	customSummaryCellRender?: (
		column: unknown[],
		index: number,
		key: string,
	) => ReactNode;
	customCellRender?: (row: Data, index: number, key: string) => ReactNode;
}

export type RowData = RowDataType;

interface BaseProps<Data extends RowData> extends TableProps<Data, string> {
	data: readonly Data[];
	page?: number;
	totalPages?: number;
	summaryRow?: boolean;
	onPageSelect?: (page: number) => void;
	rowKey?: string;
	expandable?: boolean;
	expandedRowComponent?: (row?: RowData | null) => ReactNode;
}

interface ExpandableTableProps {
	rowKey: string;
	expandable: true;
	expandedRowComponent: (row?: RowData | null) => ReactNode;
	onRowClick?: never;
}

interface RowClickTableProps<Data extends RowData> {
	rowKey?: never;
	expandable?: false;
	expandedRowComponent?: never;
	onRowClick?: (rowData: Data) => void;
}

interface ChildrenTableProps {
	children: ReactNode;
	columns?: never;
}

interface ColumnTableProps<Data extends RowData> {
	children?: never;
	columns: DataColumn<Data>[];
}

export type Props<Data extends RowData> = BaseProps<Data> &
	(ChildrenTableProps | ColumnTableProps<Data>) &
	(ExpandableTableProps | RowClickTableProps<Data>);

export function DataTable<Data extends RowData>({
	children,
	data = [],
	columns,
	page,
	totalPages,
	onPageSelect,
	onRowClick,
	summaryRow,
	expandable,
	rowKey,
	expandedRowComponent,
	...props
}: Props<Data>) {
	const [expandedRow, setExpandedRow] = useState(null);
	const className = classNames(onRowClick && style.RowClassName, style.Table);

	useAsyncCss('/css/rsuite-table.min.css');

	const columnData = useMemo(
		() =>
			columns?.map((column) =>
				data.map((row) => accessObject(row, column.key)),
			) ?? [],
		[columns, data],
	);

	const dataRows = useMemo(() => {
		if (summaryRow) {
			const totalRow = columns?.reduce((row, column, index) => {
				row[column.key] = columnData[index].reduce((total, datum) => {
					if (typeof datum !== 'number' || typeof total !== 'number') {
						return '';
					}
					return total + datum;
				}, 0);

				return row;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			}, {} as any);

			return data.concat(totalRow);
		}

		return data;
	}, [columns, summaryRow, columnData, data]);

	function handleExpanded(rowData: RowData) {
		if (expandable) {
			const rowId = accessObject(rowData, rowKey);
			if (rowId === expandedRow) {
				setExpandedRow(null);
			} else {
				setExpandedRow(rowId);
			}
		}
	}

	return (
		<>
			<Table
				data={dataRows}
				rowClassName={className}
				autoHeight
				bordered
				cellBordered
				onRowClick={onRowClick}
				style={{zIndex: 0}}
				rowKey={rowKey}
				expandedRowKeys={expandedRow ? [expandedRow] : []}
				renderRowExpanded={expandedRowComponent}
				className={style.Table}
				{...props}
			>
				{children}
				{expandable ? (
					<Column width={50}>
						<HeaderCell>#</HeaderCell>
						<Cell dataKey={rowKey} expanded>
							{(value, _index) => {
								return (
									<Button
										className={style.ExpandButton}
										icon={value.id === expandedRow ? faAngleUp : faAngleDown}
										onClick={() => handleExpanded(value)}
									/>
								);
							}}
						</Cell>
					</Column>
				) : null}
				{columns?.map(
					(
						{key, label, customCellRender, customSummaryCellRender, ...props},
						colIdx,
					) => {
						return (
							<Fragment key={key}>
								<Column
									sortable
									flexGrow={props?.width == null ? 1 : undefined}
									resizable={props?.resizable ?? props?.width != null}
									{...props}
								>
									<HeaderCell>{label}</HeaderCell>
									<Cell dataKey={key}>
										{(value, index) => {
											if (summaryRow && index === data.length) {
												if (customSummaryCellRender) {
													return customSummaryCellRender(
														columnData[colIdx],
														index,
														key,
													);
												}
												return <Paragraph bold>{value[key]}</Paragraph>;
											} else if (customCellRender) {
												return customCellRender(value as Data, index ?? 0, key);
											}

											return <>{accessObject(value, key)}</>;
										}}
									</Cell>
								</Column>
							</Fragment>
						);
					},
				)}
			</Table>
			{page != null && totalPages != null && (
				<Pagination
					page={page}
					totalPages={totalPages}
					onPageSelect={onPageSelect}
				/>
			)}
		</>
	);
}
