/* eslint-disable @typescript-eslint/naming-convention */
type Color = 'red' | 'yellow' | 'orange' | 'green' | 'grey' | 'blue';

export const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const statusColor: {[key in string]: Color} = {
	inactive: 'red',
	active: 'yellow',
	completed: 'green',
	review: 'grey',
};

export const QUALIFICATION_COLORS: {[key in string]: Color} = {
	MEMBER: 'blue',
	QUALIFIED: 'green',
	DISQUALIFIED: 'red',
	NA: 'grey',
};

export type QualificationStatus = 'QUALIFIED' | 'DISQUALIFIED';
export type QualificationStatusMap<T> = {[status in QualificationStatus]: T};
export type DocumentStatusType =
	| 'ERROR'
	| 'PENDING'
	| 'UPDATE_REQUIRED'
	| 'VERIFIED'
	| 'VALIDATED';
export const QUALIFICATION_STATUSES_NAMES: QualificationStatusMap<string> = {
	QUALIFIED: 'qual_status.qualified',
	DISQUALIFIED: 'qual_status.disqualified',
};

export const DISQUALIFICATION_REASONS_NAMES: {[key: string]: string} = {
	other_support: 'qualification.disqualification_reasons.other_support',
	age: 'qualification.disqualification_reasons.age',
	study_gap: 'qualification.disqualification_reasons.study_gap',
	requires_financing:
		'qualification.disqualification_reasons.requires_financing',
	tuition_budget: 'qualification.disqualification_reasons.tuition_budget',
	living_expenses_budget:
		'qualification.disqualification_reasons.living_expenses_budget',
	proof_of_funds: 'qualification.disqualification_reasons.proof_of_funds',
	grades: 'qualification.disqualification_reasons.grades',
	passport: 'qualification.disqualification_reasons.passport',
	document_refusal: 'qualification.disqualification_reasons.document_refusal',
	visa_refusal: 'qualification.disqualification_reasons.visa_refusal',
	app_fee_refusal: 'qualification.disqualification_reasons.app_fee_refusal',
	expired_visa: 'qualification.disqualification_reasons.expired_visa',
	english_proficiency_test:
		'qualification.disqualification_reasons.english_proficiency_test',
	sponsor_support: 'qualification.disqualification_reasons.sponsor_support',
	financing_amount: 'qualification.disqualification_reasons.financing_amount',
	financially_prepared:
		'qualification.disqualification_reasons.financially_prepared',
	sponsor_relationship:
		'qualification.disqualification_reasons.sponsor_relationship',
	td_funds_available:
		'qualification.disqualification_reasons.td_funds_available',
	immigration_pof: 'qualification.disqualification_reasons.immigration_pof',
};

export const QUALIFICATION_STATUSES = [
	...(Object.keys(
		QUALIFICATION_STATUSES_NAMES,
	) as (keyof typeof QUALIFICATION_STATUSES_NAMES)[]),
];

export type LevelOfStudy =
	| 'BACHELORS'
	| 'MASTERS'
	| 'UNDERGRAD_DIPLOMA'
	| 'POSTGRAD_DIPLOMA'
	| 'UNDERGRAD_CERTIFICATE'
	| 'POSTGRAD_CERTIFICATE'
	| 'ASSOCIATE_DEGREE';

export const STUDY_LEVELS: {[key in LevelOfStudy]: string} = {
	BACHELORS: 'study_levels.bachelors',
	MASTERS: 'study_levels.masters',
	UNDERGRAD_DIPLOMA: 'study_levels.undergrad_diploma',
	POSTGRAD_DIPLOMA: 'study_levels.postgrad_diploma',
	UNDERGRAD_CERTIFICATE: 'study_levels.undergrad_certificate',
	POSTGRAD_CERTIFICATE: 'study_levels.postgrad_certificate',
	ASSOCIATE_DEGREE: 'study_levels.associate_degree',
};

export const BOOLEAN = {
	Yes: true,
	No: false,
};

export const BOOLEAN_STRING = {
	true: 'yes',
	false: 'no',
};

export type PassportStatus = 'YES' | 'NO' | 'APPLIED';

export const PASSPORT_STATUS: {[key in PassportStatus]: string} = {
	YES: 'passport_status.yes',
	NO: 'passport_status.no',
	APPLIED: 'passport_status.applied',
};

export type SponsorRelationship =
	| 'PARENT'
	| 'SIBLING'
	| 'UNCLE'
	| 'OTHER_FAMILY'
	| 'FRIEND';

export const SPONSOR_RELATIONSHIPS: {[key in SponsorRelationship]: string} = {
	PARENT: 'sponsor_relationships.parent',
	SIBLING: 'sponsor_relationships.sibling',
	UNCLE: 'sponsor_relationships.uncle',
	OTHER_FAMILY: 'sponsor_relationships.other_family',
	FRIEND: 'sponsor_relationships.friend',
};

export type SponsorSupport = 'TUITION' | 'TUITION_DEPOSIT' | 'PROOF_OF_FUNDS';

export const SPONSOR_SUPPORT: {[key in SponsorSupport]: string} = {
	TUITION: 'sponsor_support.tuition',
	TUITION_DEPOSIT: 'sponsor_support.tuition_deposit',
	PROOF_OF_FUNDS: 'sponsor_support.proof_of_funds',
};

export type FinancingAmount = 'ALL' | 'HALF' | 'MINIMAL';

export const FINANCING_AMOUNT: {[key in FinancingAmount]: string} = {
	ALL: 'financing_amount.all',
	HALF: 'financing_amount.half',
	MINIMAL: 'financing_amount.minimal',
};

export type CoachingFunnelStageMap<T> = {[stage in CoachingFunnelStage]: T};
export type FunnelStageMap<T> = {[stage in FunnelStage]: T};

export const COACHING_FUNNEL_STAGE_NAMES: CoachingFunnelStageMap<string> = {
	GET_STARTED: 'students.funnel_stages.get_started',
	DISCOVER: 'students.funnel_stages.discover',
	PREP: 'students.funnel_stages.prep',
	SUBMIT: 'students.funnel_stages.submit',
	ACCEPTED: 'students.funnel_stages.accepted',
	VISA: 'students.funnel_stages.visa',
	SET_UP: 'students.funnel_stages.set_up',
	PLACED: 'students.funnel_stages.placed',
};

export const FUNNEL_STAGE_NAMES: FunnelStageMap<string> = {
	SIGNUP: 'students.funnel_stages.signup',
	VERIFICATION: 'students.funnel_stages.verification',
	...COACHING_FUNNEL_STAGE_NAMES,
};

export const STUDENT_STATUSES_SLUG: {
	[key: string]: CoachingFunnelStage | 'ARCHIVED';
} = {
	start: 'GET_STARTED',
	discover: 'DISCOVER',
	prep: 'PREP',
	apply: 'SUBMIT',
	accepted: 'ACCEPTED',
	visa: 'VISA',
	arrive: 'SET_UP',
	placed: 'PLACED',
	archived: 'ARCHIVED',
};

export type StudentGroup =
	| 'ONSHORE'
	| 'OFFSHORE'
	| 'ENGLISH_EXEMPT'
	| 'DOMESTIC';

export const STUDENT_GROUP_TYPES: {[key in StudentGroup]: string} = {
	ONSHORE: 'students.groups.onshore',
	OFFSHORE: 'students.groups.offshore',
	ENGLISH_EXEMPT: 'students.groups.english_exempt',
	DOMESTIC: 'students.groups.domestic',
};

export type Ambassador =
	| 'SUB_AGENT'
	| 'BRAND_AMBASSADOR'
	| 'INFLUENCER'
	| 'SCHOOL_PARTNER'
	| 'BUSINESS_PARTNER'
	| 'AMBASSADOR'
	| 'OTHER';

export const AMBASSADOR_TYPES: {[key in Ambassador]: string} = {
	SUB_AGENT: 'ambassador.ambassador_types.sub_agent',
	BRAND_AMBASSADOR: 'ambassador.ambassador_types.brand_ambassador',
	INFLUENCER: 'ambassador.ambassador_types.influencer',
	SCHOOL_PARTNER: 'ambassador.ambassador_types.school_partner',
	BUSINESS_PARTNER: 'ambassador.ambassador_types.business_partner',
	AMBASSADOR: 'ambassador.ambassador_types.ambassador',
	OTHER: 'ambassador.ambassador_types.other',
};

export const CURRENT_MAJOR_TYPES: {[key: string]: string} = {
	Business: 'Business',
	Sciences: 'Sciences',
	Engineering: 'Engineering',
	'Math, Engineering, Computer Science': 'Math, Engineering & Computer Science',
	'Creative Arts': 'Creative Arts',
	'Social Studies': 'Social Studies',
	'Social Studies & Humanities': 'Social Studies & Humanities',
	Education: 'Education',
	'Skilled Trades': 'Skilled Trades',
	Other: 'Other',
};

export type ProgressCardStages = Extract<
	FunnelStage,
	| 'GET_STARTED'
	| 'DISCOVER'
	| 'PREP'
	| 'ACCEPTED'
	| 'VISA'
	| 'SET_UP'
	| 'PLACED'
>;

export const PROGRESS_STEP_NAMES: {[key in ProgressCardStages]: string} = {
	GET_STARTED: 'Get Started',
	DISCOVER: 'Discover Programs',
	PREP: 'Prep Applications',
	ACCEPTED: 'Track Offers',
	VISA: 'Apply for Visa',
	SET_UP: 'Set Up',
	PLACED: 'Land on Campus',
};

export const PROGRESS_STEPS = [
	...(Object.keys(PROGRESS_STEP_NAMES) as (keyof typeof PROGRESS_STEP_NAMES)[]),
];
export type ProgressStep = (typeof PROGRESS_STEPS)[number];

export const COACHING_FUNNEL_STAGES = [
	...(Object.keys(
		COACHING_FUNNEL_STAGE_NAMES,
	) as (keyof typeof COACHING_FUNNEL_STAGE_NAMES)[]),
];

export const FUNNEL_STAGES = [
	...(Object.keys(FUNNEL_STAGE_NAMES) as (keyof typeof FUNNEL_STAGE_NAMES)[]),
];

export type CoachingPipeline =
	| 'VERIFICATION_MESSAGING'
	| 'VERIFICATION_CALLING'
	| 'VERIFICATION_MESSAGING_ONSHORE'
	| 'VERIFICATION_CALLING_ONSHORE'
	| 'VERIFICATION_AUTO_CALLING'
	| 'COACHING';

export type CoachingPipelineMap<T> = {[pipeline in CoachingPipeline]: T};

export const COACHING_PIPELINE_NAMES: CoachingPipelineMap<string> = {
	VERIFICATION_MESSAGING: 'coach.pipelines.verification_messaging',
	VERIFICATION_CALLING: 'coach.pipelines.verification_calling',
	VERIFICATION_MESSAGING_ONSHORE:
		'coach.pipelines.verification_messaging_onshore',
	VERIFICATION_CALLING_ONSHORE: 'coach.pipelines.verification_calling_onshore',
	VERIFICATION_AUTO_CALLING: 'coach.pipelines.verification_auto_calling',
	COACHING: 'coach.pipelines.coaching',
};

export const COACHING_PIPELINES = [
	...(Object.keys(
		COACHING_PIPELINE_NAMES,
	) as (keyof typeof COACHING_PIPELINE_NAMES)[]),
];

export type FunnelStage =
	| 'SIGNUP'
	| 'VERIFICATION'
	| 'GET_STARTED'
	| 'DISCOVER'
	| 'PREP'
	| 'SUBMIT'
	| 'ACCEPTED'
	| 'VISA'
	| 'SET_UP'
	| 'PLACED';

export type CoachingFunnelStage = Exclude<
	FunnelStage,
	'SIGNUP' | 'VERIFICATION'
>;

export type ApplicationProfileStepType =
	| 'NOT_STARTED'
	| 'PERSONAL_INFORMATION'
	| 'ADDRESS_DETAILS'
	| 'EDUCATION_SUMMARY'
	| 'ENGLISH_TEST_SCORE'
	| 'BACKGROUND_INFORMATION'
	| 'EMERGENCY_CONTACT'
	| 'COMPLETED';

export const APPLICATION_PROFILE_STAGES: ApplicationProfileStepType[] = [
	'NOT_STARTED',
	'PERSONAL_INFORMATION',
	'ADDRESS_DETAILS',
	'EDUCATION_SUMMARY',
	'ENGLISH_TEST_SCORE',
	'BACKGROUND_INFORMATION',
	'EMERGENCY_CONTACT',
	'COMPLETED',
];

export type MaritalStatus = 'SINGLE' | 'MARRIED';

export const MARITAL_STATUS_NAMES: {[key in MaritalStatus]: string} = {
	SINGLE: 'application_profile.personal_information.marital_statuses.single',
	MARRIED: 'application_profile.personal_information.marital_statuses.married',
};

export type Gender = 'MALE' | 'FEMALE';

export const GENDER_NAMES: {[key in Gender]: string} = {
	MALE: 'application_profile.personal_information.genders.male',
	FEMALE: 'application_profile.personal_information.genders.female',
};

export interface Currency {
	amount: number;
	currency: string;
}

export const COST_BY_STUDY_LEVEL: {[key in LevelOfStudy]: string} = {
	MASTERS: '50,780',
	POSTGRAD_DIPLOMA: '47,730',
	POSTGRAD_CERTIFICATE: '45,230',
	UNDERGRAD_DIPLOMA: '44,230',
	UNDERGRAD_CERTIFICATE: '44,230',
	BACHELORS: '48,780',
	ASSOCIATE_DEGREE: '48,780',
};
